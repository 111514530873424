@import '../../../styles/customMediaQueries.css';

.root {
  width: 100%;

  /* Top Bar's drop shadow should always be visible */
  z-index: var(--zIndexTopbar);
  position: relative;
}

.container {
  /* Size */
  width: 100%;
  height: var(--topbarHeight);

  /* Layout for child components */
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  /* fill */
  background-color: var(--colorLightBeige);
  border-bottom: 1px solid var(--colorDarkBeige);

  @media (--viewportLarge) {
    height: var(--topbarHeightDesktop);
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.logo {
  display: inline-block;
  height: 24px;
  margin-left: 12px;
}

.logoRoot > div {
  height: 24px;
}

.logoImage {
  height: 24px;
  width: auto;
}

.logoutButton {
  margin: 0;
}

.topbarMobileItems {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.topbarMobileButtons {
  align-items: center;
  display: flex;
}

.topbarMobileSignup {
  align-items: center;
  background: var(--marketplaceColor);
  border-radius: 0.5rem;
  color: var(--colorWhite);
  display: inline-flex;
  height: 40px;
  justify-content: center;
  margin: 6px;
  padding: 12px 18px;
}

.topbarMobileLogin {
  align-items: center;
  background: var(--colorWhite);
  border-radius: 0.5rem;
  color: var(--colorForeground);
  display: inline-flex;
  height: 40px;
  justify-content: center;
  margin: 6px 6px 6px 0;
  padding: 12px;
}

.topbarMobileSignup svg,
.topbarMobileLogin svg {
  fill: none;
  stroke: currentColor;
}

.topbarMobileSignup svg {
  margin-left: 6px;
}

/* Child component's size (and positioning) should be given here */
.menu,
.searchMenu {
  align-items: center;
  background: var(--colorWhite);
  border-radius: 0.5rem;
  display: flex;
  height: 40px;
  justify-content: space-between;
  margin: 0 6px;
  width: auto;

  /* Hovers */
  &:enabled {
    cursor: pointer;
  }
  &:disabled {
    cursor: auto;
  }
}

.menu {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 10px 6px 10px 10px;
  position: relative;
  width: 46px;
}
.searchMenu {
  width: calc(100% - 12px);
}

.searchMenu span {
  color: var(--colorPlaceholder);
  font-size: 16px;
  font-weight: var(--fontWeightRegular);
  line-height: 18px;
  margin-left: 18px;
}

.topbarMobileRight {
  align-items: center;
  display: flex;
}

.hey,
.firstName {
  color: var(--colorForeground);
  font-size: 14px;
  font-weight: var(--fontWeightRegular);
  line-height: 16px;
  display: block;
  margin-right: 6px;
  text-align: right;

  @media (--viewportLarge) {
    display: inline-block;
  }
}

.firstName {
  font-weight: var(--fontWeightSemiBold);
}

.menuIcon,
.searchMenuIcon {
}

.searchMenuIcon {
  fill: none;
  margin-right: 12px;
}

.notificationDot {
  /* Dimensions
  width: 14px;
  height: 14px;
  border-radius: 50%; */

  /* Position */
  position: absolute;
  top: -8px;
  right: -4px;

  /* Style: red dot with white border */
  /* background-color: var(--colorFail); */
  border: 2px solid var(--colorWhite);

  text-align: center;
  line-height: 1; /* Ensure there's no extra vertical space */

  /* Centering the text inside the dot */
  display: inline-flex; /* Use inline-flex to keep the span inline while centering content */
  align-items: center; /* Vertically center content */
  justify-content: center; /* Horizontally center content */

  animation-name: notificationPop;
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;

  animation-timing-function: ease-in;
  opacity: 0;
  transform: scale(0);

  @media (--viewportMedium) {
    top: 23px;
  }
}

@keyframes notificationPop {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.desktop {
  display: none;

  @media (--viewportLarge) {
    display: block;
  }
}

/* Icon component styles */
.rootMenuIcon {
  fill: var(--colorGrey700);
}
.rootSearchIcon {
  stroke: var(--colorGrey700);
}

.searchContainer {
  position: relative;
  height: 100%;
  margin-top: 94px;
}

.mobileHelp {
  color: var(--colorGrey300);
  margin: 0 24px;

  /* Absolute position to avoid affecting the layout of the autocomplete
   predictions list */
  position: absolute;
  top: 0px;
}

/* ================ Modal ================ */

.modalContainer {
  composes: marketplaceModalInMobileBaseStyles from global;
  padding: 0;
  padding: var(--modalPadding);
  background-color: var(--colorLightBeige);

  @media (--viewportMedium) {
    flex-basis: 576px;
    flex-grow: 1;
    min-height: max(100%, 100vh);
    height: auto;
    padding: 24px;
    border-bottom: none;
  }
}
.modalContainerSearchForm {
  composes: modalContainer;
  padding: 0;
  @media (--viewportMedium) {
    padding: var(--modalPaddingMedium);
  }
}

.missingInformationModal {
  composes: marketplaceModalBaseStyles from global;
  padding-top: 70px;
}

/* Title of the modal */
.modalTitle {
  composes: marketplaceModalTitleStyles from global;
}

/* Paragraph for the Modal */
.modalMessage {
  composes: marketplaceModalParagraphStyles from global;
}

/* Make the email pop */
.email {
  font-weight: var(--fontWeightHighlightEmail);
}

.helperLink {
  composes: marketplaceModalHelperLink from global;
}

.helperText {
  composes: marketplaceModalHelperText from global;
}

.error {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;

  /* Align the helper links to the left since there isn't a Primary Button anymore */
  text-align: left;
}

.modalIcon {
  composes: marketplaceModalIconStyles from global;
}

.genericError {
  position: fixed;
  top: calc(var(--topbarHeight) + 15px);

  /* Place enough to the right to hide the error */
  right: -355px;
  opacity: 0;

  max-width: 340px;
  margin: 0 15px;

  /* Animate error from the right */
  transition: all ease-out 0.2s;
  transition-delay: 1s;

  /* Bleed the bg color from the bottom */
  padding-bottom: 4px;
  background-color: var(--marketplaceColor);
  border-radius: var(--borderRadiusMedium);

  /* Place on top of everything */
  z-index: var(--zIndexGenericError);

  @media (--viewportMedium) {
    top: calc(var(--topbarHeightDesktop) + 15px);
  }
}

.genericErrorVisible {
  right: 0;
  opacity: 1;
}

.genericErrorContent {
  padding: 16px 22px;
  background-color: var(--marketplaceColorLight);
  border-radius: var(--borderRadiusMedium);
}

.genericErrorText {
  composes: h5 from global;
  font-weight: var(--fontWeightSemiBold);
  margin: 0;
  color: var(--colorWhite);

  @media (--viewportMedium) {
    margin: 0;
  }
}
