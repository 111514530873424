@import '../../../../styles/customMediaQueries.css';

.root {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: auto;
}

.content {
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.accountLinksWrapper,
.customLinksWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}
.accountLinksWrapper {
  margin-bottom: 12px;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 24px;
  background-color: var(--colorWhite);
  box-shadow: var(--boxShadowTop);
}

.logo {
  display: inline-block;
  height: 24px;
  margin-left: 12px;
  left: 0;
  position: absolute;
  top: 18px;
}

.logoRoot > div {
  height: 24px;
}

.logoImage {
  height: 24px;
  width: auto;
}

.greeting {
  position: absolute;
  right: 62px;
  top: 14px;
}

.hey,
.firstName {
  color: var(--colorForeground);
  font-size: 14px;
  font-weight: var(--fontWeightRegular);
  line-height: 16px;
  display: block;
  margin-right: 6px;
  text-align: right;

  @media (--viewportLarge) {
    display: inline-block;
  }
}

.firstName {
  font-weight: var(--fontWeightSemiBold);
}

.currentPageLink {
  color: var(--marketplaceColorDark);
}

.notificationBadge {
  position: absolute;
  left: 12px;
  top: 0;
  font-size: 16px !important;

  /* This is inside a link - remove underline */
  &:hover {
    text-decoration: none;
  }
}

.createNewListingLink {
  composes: buttonPrimary from global;
}

.authenticationGreeting {
  /* Font */
  composes: h1 from global;

  margin-bottom: 48px;
  margin-top: var(--TopbarMobileMenu_topMargin);
}
.authenticationLinks {
  white-space: nowrap;
}

.signupLink {
  text-decoration: none;
  white-space: nowrap;
  color: var(--marketplaceColor);
}
.loginLink {
  text-decoration: none;
  white-space: nowrap;
  color: var(--marketplaceColor);
}

.currentPage {
}

.spacer {
  width: 100%;
  height: 124px;
}

.menuHeading {
  font-size: 46px;
  font-weight: var(--fontWeightRegular);
  line-height: 50px;
  margin-top: 84px;
}

.menuLink {
  composes: textSmall from global;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  /* Dimensions */
  width: 100%;
  min-width: 276px;
  margin: 12px 0;

  /* Layout details */
  color: var(--colorGrey700);
  text-align: left;
  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--colorBlack);
    text-decoration: none;

    & .menuItemBorder {
      width: 6px;
      background-color: var(--marketplaceColor);
    }
  }
}

.menuLink > span {
  color: var(--colorForeground);
  font-size: 24px;
  font-weight: var(--fontWeightRegular);
  line-height: 24px;
  margin-left: 24px;
}

.menuLink .iconWrapper,
.settingsLink .iconWrapper {
  align-items: center;
  background: var(--colorWhite);
  border-radius: 50%;
  display: inline-flex;
  height: 48px;
  justify-content: center;
  width: 48px;
}

.menuLink svg {
  fill: none;
}

.menuDivider {
  border: 0;
  border-bottom: 2px solid var(--colorDarkBeige);
  margin: 36px 0;
  width: 100%;
}

.settingsLink .iconWrapper {
  background: var(--colorForeground);
}

.settingsLink svg {
  fill: none;
  stroke: var(--colorWhite);
}

.settingsLink span {
  font-size: 16px;
  font-weight: var(--fontWeightRegular);
  line-height: 19px;
}

.profileLink {
  align-items: center;
  background: var(--colorDarkBeige);
  border-radius: 0.5rem;
  display: inline-flex;
  justify-content: center;
  margin-bottom: 24px;
  margin-right: 6px;
  min-width: auto;
  padding: 15px 20px;
  width: auto;

  &:hover {
    background: var(--colorWhite);
  }
}

.profileLink > span {
  font-size: 16px;
  font-weight: var(--fontWeightMedium);
  margin-left: 0;
}

.profileLink svg {
  fill: none;
  margin-left: 6px;
  stroke: currentColor;
}

.logoutButton {
  background: var(--marketplaceColor);
  border-radius: 0.5rem;

  display: inline-block;

  /* Text size should be inherited */
  text-decoration: none;

  /* Font is specific to this component */
  font-weight: var(--fontWeightMedium);
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0;

  /* Dimensions */
  position: relative;
  width: auto;
  margin-left: 12px;
  margin-top: 0;
  margin-right: 18px;
  padding: 15px 20px;

  /* Layout details */
  color: var(--colorWhite);
  text-align: left;
  white-space: nowrap;
  transition: var(--transitionStyleButton);

  &:hover {
    background: var(--marketplaceColorDark);
    cursor: pointer;
    text-decoration: none;
  }

  @media (--viewportMedium) {
    line-height: 16px;
    margin-bottom: 0;
  }
}

.logoutButton svg {
  fill: none;
  margin-left: 6px;
  stroke: currentColor;
}
