@import '../../../../styles/customMediaQueries.css';

.mobileInputRoot {
  width: 100%;
}

.desktopInputRoot {
}

.fontInput {
  font-weight: var(--fontWeightRegular);
  font-size: 18px;
  line-height: 24px;

  /* Layout */
  margin: 0 6px;
  padding: 8px 13px 10px 13px;
}

.mobileInput {
  /* Font */
  composes: fontInput;
  font-size: 16px;

  /* Layout */
  height: 42px;

  /* Borders */
  border-radius: 0.5rem;

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }
}

.mobileIcon,
.desktopIcon {
  align-items: center;
  background: var(--colorDarkBeige);
  border: none;
  border-radius: 0.5rem;
  bottom: 0;
  display: flex;
  font-size: 16px;
  font-weight: var(--fontWeightMedium);
  height: 46px;
  justify-content: center;
  margin-bottom: auto;
  margin-right: 5px;
  margin-top: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 138px;

  &:hover {
    background: var(--colorLightBeige);
  }
}

.mobileIcon {
  height: 34px;
  margin-right: 10px;
  width: 112px;
}

.desktopIcon svg,
.mobileIcon svg {
  fill: none;
  margin-left: 12px;
}

.desktopInput {
  composes: p from global;
  flex-grow: 1;
  /* Font */
  height: 54px;
  color: var(--colorForeground);
  font-weight: var(--fontWeightRegular);
  font-size: 15px;
  line-height: 18px;
  border: 1px solid var(--colorWhite);
  border-radius: 0.5rem;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 18px;
  width: 100%;

  /* Search text gets ellipsis if it's too wide */
  text-overflow: ellipsis;
  overflow-x: hidden;

  margin-top: 0;
  margin-bottom: 0;

  &:hover {
    border: 1px solid var(--colorForeground);
    border-radius: 0.5rem;
  }

  &:focus {
    border: 1px solid var(--colorForeground);
  }

  &:hover::placeholder,
  &:focus::placeholder {
    color: var(--colorGrey700);
  }

  /* Placeholder text might be too wide */
  &::placeholder {
    color: var(--colorPlaceholder);
    font-size: 18px;
    font-weight: var(--fontWeightRegular);
    line-height: 18px;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mobilePredictions {
  position: absolute;
  top: 50px;
  left: 18px;
  /* searchContainer margin-top (94px) + mobileInput height (50px) + bottom spacing (96px) = 240px */
  min-height: calc(100vh - 240px);
  width: calc(100% - 36px);
  background-color: var(--colorWhite);
  border-radius: 0 0 0.5rem 0.5rem;
  border: 1px solid var(--colorForeground);
  z-index: calc(var(--zIndexPopup) + 1);

  @media (--viewportMedium) {
    min-height: unset;
  }
}

.mobilePredictionsAttribution {
  /* When using the Google Maps Places geocoder, the "Powered by Google"
   text is hidden in Mobile Safari without giving some extra space to
   it. */
  margin-bottom: 100px;
}

.desktopPredictions {
  position: absolute;
  width: 100%;
  top: 54px;
  left: 0;
  background-color: var(--colorWhite);
  border-radius: 0 0 0.5rem 0.5rem;
  border: 1px solid var(--colorForeground);
  z-index: calc(var(--zIndexPopup) + 1);

  margin-top: 0;
  max-width: 434px;
}

/**
 * Keyword search
 */
.keywordSearchWrapper {
  position: relative;
  display: flex;
}

.searchSubmit {
  border: 0;
  padding: 0;
  cursor: pointer;

  &:hover,
  &:focus {
    outline: none;

    & .iconSvgGroup {
      stroke: var(--colorGrey700);
    }
  }
}

.icon {
  display: flex;
  width: 24px;
  align-self: stretch;
  border-bottom: 2px solid var(--marketplaceColor);
  background-color: var(--colorWhite);
}

.iconSvg {
  margin: auto;
}

.iconSvgGroup {
  stroke: var(--marketplaceColor);
  stroke-width: 2.5px;

  @media (--viewportMedium) {
    stroke-width: 2px;
  }
}
